// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {lna56jAlp: {hover: true}};

const cycleOrder = ["lna56jAlp"];

const variantClassNames = {lna56jAlp: "framer-v-gkrpsg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "lna56jAlp", image: Lm5UZcUvl = {src: new URL("assets/Sw8QSvCFTHipv8ANpXyQ9usuIo.jpeg", import.meta.url).href, srcSet: `${new URL("assets/512/Sw8QSvCFTHipv8ANpXyQ9usuIo.jpeg", import.meta.url).href} 323w, ${new URL("assets/1024/Sw8QSvCFTHipv8ANpXyQ9usuIo.jpeg", import.meta.url).href} 647w, ${new URL("assets/Sw8QSvCFTHipv8ANpXyQ9usuIo.jpeg", import.meta.url).href} 860w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lna56jAlp", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-DVYFm", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<Image {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 862, intrinsicWidth: 1001, pixelHeight: 862, pixelWidth: 1001, ...toResponsiveImage_194x2gw(Lm5UZcUvl)}} className={cx("framer-gkrpsg", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lna56jAlp"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition} variants={{"lna56jAlp-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"lna56jAlp-hover": {"data-framer-name": undefined, tabIndex: 0}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-DVYFm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DVYFm * { box-sizing: border-box; }", ".framer-DVYFm .framer-14ba0do { display: block; }", ".framer-DVYFm .framer-gkrpsg { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 773px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1080px; }", ".framer-DVYFm .framer-v-gkrpsg .framer-gkrpsg { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DVYFm .framer-gkrpsg { gap: 0px; } .framer-DVYFm .framer-gkrpsg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-DVYFm .framer-gkrpsg > :first-child { margin-left: 0px; } .framer-DVYFm .framer-gkrpsg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 773
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"I4m4j6r0s":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Lm5UZcUvl":"image"}
 */
const FramerdJJ97bncQ: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerdJJ97bncQ;

FramerdJJ97bncQ.displayName = "Sparetime register 2";

FramerdJJ97bncQ.defaultProps = {height: 773, width: 1080};

addPropertyControls(FramerdJJ97bncQ, {Lm5UZcUvl: {__defaultAssetReference: "data:framer/asset-reference,Sw8QSvCFTHipv8ANpXyQ9usuIo.jpeg?originalFilename=gocoop-approve-enquiry.jpeg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerdJJ97bncQ, [])